class Criteria {
    private lv: LifeVine;
    private page: JQuery;
    private form: JQuery;
    private list: JQuery;
    private listTemplate: HandlebarsTemplateDelegate;

    private criteriaId: number = null;

    private emptyCriterion = {
        name: ''
    };

    constructor(lv: LifeVine) {
        this.lv = lv;

        this.page = jQuery('#page-criteria');
        this.form = this.page.find('form');
        this.list = this.page.find('ul');

        this.listTemplate = Handlebars.compile(this.page.find('#criteria-list-template').html());

        this.form.on('click', 'button', event => this.formButtonClick(event));
    }

    public index() {
        this.criteriaId = null;
        this.loadList();
        this.renderForm(this.emptyCriterion, null);
    }

    public edit(id) {
        if (this.list.html() === '') {
            this.loadList();
        }

        this.criteriaId = id;
        this.lv.criteria().get(id)
            .done(criterion => this.renderForm(criterion, 'edit'))
            .fail(xhr => {
                Materialize.toast('Could not load criterion', 2000);
                history.back();
            });
    }

    public create() {
        if (this.list.html() === '') {
            this.loadList();
        }

        this.criteriaId = null;
        this.renderForm(this.emptyCriterion, 'create');
    }

    public loadList() {
        this.lv.criteria().get()
            .done(criteria => this.list.html(this.listTemplate(criteria)))
            .fail(xhr => Materialize.toast(xhr.responseJSON.error, 2000));
    }

    public renderForm(criterion, action) {
        setFormData(this.form, criterion);
        Materialize.updateTextFields();
        let buttons = this.form.find('button');
        if (action === 'edit') {
            this.form.find('input').prop('disabled', false);
            buttons.prop('disabled', false);
        } else if (action === 'create') {
            this.form.find('input').prop('disabled', false);
            buttons.prop('disabled', false);
            buttons.filter('[type="button"]').prop('disabled', true);
        } else {
            this.form.find('input').prop('disabled', true);
            buttons.prop('disabled', true);
        }
    }

    private formButtonClick(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let action = jQuery(event.target).attr('type');
        if (action === 'submit') {
            this.save();
        } else if (action === 'reset') {
            history.back();
        } else if (action === 'button') {
            this.remove();
        }
        this.lv.criteria();
    }

    private save() {
        let data = getFormData(this.form);
        let promise = this.criteriaId ? this.lv.criteria().save(this.criteriaId, data) : this.lv.criteria().create(data);
        promise
            .done(data => history.back())
            .fail(xhr => {
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.errors) {
                        this.setErrors(xhr.responseJSON.errors);
                    } else {
                        Materialize.toast(xhr.responseJSON.error, 2000);
                    }
                } else {
                    Materialize.toast('There was a problem saving', 2000);
                }
            });
    }

    private remove() {
        this.lv.criteria().remove(this.criteriaId)
            .done(() => history.back())
            .fail(xhr => Materialize.toast(xhr.responseJSON.error, 2000));
    }

    private setErrors(errors) {
        jQuery.each(errors, (field, message) => {
            let input = this.form.find(`[name="${field}"]`);
            this.form.find(`[for="${input[0].id}"]`).attr('data-error', message)[0].id;
            input.removeClass('valid').addClass('invalid');
        });
    }
}

window.app.page('page-criteria', () => {
    return params => {
        if (!window.controllers.criteria) {
            window.controllers.criteria = new Criteria(window.lifeVine);
        }
        let controller = window.controllers.criteria;

        if (!params) {
            controller.index();
        } else if (params === 'create') {
            controller.create();
        } else {
            controller.edit(params);
        }
    };
});